<template>
  <div v-if="isLoaded" class="container">
    <!-- <Concat v-if="isCancat" /> -->

    <div class="box1">
      <img alt="Vue logo" class="logo-img"
        src="https://oss.xiaoshufenji.com/homePage/images/logo.png">
      <div style="display: flex;">
        <div style="cursor: pointer;" class="box1-title" @click="notCancat" >首页</div>
        <a href="https://www.xiaoshufenji.com/contactus.html" style="cursor: pointer;text-decoration: none;" class="box1-title">关于我们</a>
      </div>
    </div>
    <div class="content" v-if="isCancat">
      <div class="item">
        <div class="title">微信客服：</div>
        <div class="cont">xiaoshufenji01</div>
      </div>
      <div class="item">
        <div class="title">联系邮箱：</div>
        <div class="cont">xiaoshufenji@qq.com</div>
      </div>
    </div>
    <div v-else>
      <div class="box2">
        <img alt="Vue logo" v-show="is_device != 'phone'" class="wenan-img"
          src="https://oss.xiaoshufenji.com/homePage/images/pic-wenan.png">
        <img alt="Vue logo" v-show="is_device == 'phone'" class="wenan-img"
          src="https://oss.xiaoshufenji.com/homePage/images/phone-wenan.png">
        <div class="phone-imgBox" v-show="is_device == 'pc'">
          <img alt="Vue logo" class="phone-imgs"
            src="https://oss.xiaoshufenji.com/homePage/images/pic-phone.png">
          <img alt="Vue logo" class="pc-qrcode"
            src="https://oss.xiaoshufenji.com/homePage/images/qrCodeXcx.png">
        </div>
        <div class="phone-imgBox" v-show="is_device == 'ipad'">
          <img alt="Vue logo" class="phone-imgs"
            src="https://oss.xiaoshufenji.com/homePage/images/pad-phone.png">
          <img alt="Vue logo" class="pad-qrcode"
            src="https://oss.xiaoshufenji.com/homePage/images/qrCodeXcx.png">
        </div>

        <div class="phone-imgBox" v-show="is_device == 'phone'">
          <img alt="Vue logo" class="phone-imgs"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-phone.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="qrCodeImg"
            src="https://oss.xiaoshufenji.com/homePage/images/qrCodeXcx.png">
        </div>

        <img alt="Vue logo" v-show="is_device != 'phone'" class="zhuang-img"
          src="https://oss.xiaoshufenji.com/homePage/images/pic-zhuangshi.png">
        <img alt="Vue logo" v-show="is_device == 'phone'" class="zhuang-img"
          src="https://oss.xiaoshufenji.com/homePage/images/phone-zhuangshi.png">
      </div>
      <div class="box3">
        <div class="box3-1">
          <img alt="Vue logo" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box3-1.png">
        </div>
        <div class="box3-2">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box3-2.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box3-2.png">
        </div>
        <div class="box3-3">
          <div class="box3-3-1">
            <div class="box3-3-img">
              <img alt="Vue logo" class="box3-img"
                src="https://oss.xiaoshufenji.com/homePage/images/box3-3.png">
              <img alt="Vue logo" v-show="is_device != 'phone'" class="chatu1"
                src="https://oss.xiaoshufenji.com/homePage/images/chatu1.png">
              <img alt="Vue logo" v-show="is_device != 'phone'" class="chatu2"
                src="https://oss.xiaoshufenji.com/homePage/images/chatu2.png">
            </div>
            <p class="box3-p">我们精选考试同源外刊，相似内容主题，
              提前积累专业词汇量</p>
          </div>
          <div class="box3-div">
            <div class="box3-item">
              <img alt="Vue logo" class="box3-img"
                src="https://oss.xiaoshufenji.com/homePage/images/news1.png">
            </div>
            <div class="box3-item">
              <img alt="Vue logo" class="box3-img"
                src="https://oss.xiaoshufenji.com/homePage/images/news2.png">
            </div>
            <div class="box3-item">
              <img alt="Vue logo" class="box3-img"
                src="https://oss.xiaoshufenji.com/homePage/images/news3.png">
            </div>
            <div class="box3-item">
              <img alt="Vue logo" class="box3-img"
                src="https://oss.xiaoshufenji.com/homePage/images/news4.png">
            </div>
          </div>
        </div>
      </div>
      <div class="box4">
        <div class="box4-1">
          <img alt="Vue logo" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box4-1.png">
        </div>
        <div class="box4-2">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box4-2.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box4-2.png">
        </div>
      </div>
      <div class="box5">
        <div class="box5-1">
          <img alt="Vue logo" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box4-1.png">
        </div>
        <div class="box5-2">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box5-2.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-2.png">
        </div>
        <div class="box5-3">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box5-3.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-3.png">
        </div>
        <div class="box5-4">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box5-4.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-4.png">
        </div>
        <div class="box5-5">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/box5-5.png">
          <img alt="Vue logo" v-show="is_device != 'phone'" class="qrCodeXcx"
            src="https://oss.xiaoshufenji.com/homePage/images/qrCodeXcx.png">
          <img alt="Vue logo" v-show="is_device == 'phone'" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-5.png">
        </div>
        <div class="box5-6" v-show="is_device == 'phone'">
          <img alt="Vue logo" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-6.png">
        </div>
        <div class="box5-7" v-show="is_device == 'phone'">
          <img alt="Vue logo" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-7.png">
        </div>
        <div class="box5-8" v-show="is_device == 'phone'">
          <img alt="Vue logo" class="box3-img"
            src="https://oss.xiaoshufenji.com/homePage/images/phone-box5-8.png">
          <img alt="Vue logo" class="box3Qrcode"
            src="https://oss.xiaoshufenji.com/homePage/images/qrCodeXcx.png">
        </div>
      </div>
    </div>
    <div class="box6">
      <p style="width: 100%;text-align:center;">沪ICP备2024078499号 ｜ 2024-06-28 ｜ 上海小树分级科技有限公司
        ｜ 沪ICP备2024078499号-1 </p>
    </div>
  </div>

  <div v-else style="padding: 0 29.3rem;
    display: flex;
    justify-content: space-between;">
    <img alt="Vue logo" style="height: 11.7rem;"
      src="https://oss.xiaoshufenji.com/homePage/images/logo.png">
    <div style="padding: 4.1rem 3.3rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 2.5rem;
    color: #0a9e3d;
    line-height: 3.5rem;">首页</div>
    
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';

export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
 
  mounted() {

  },
  setup() {
    const isLoaded = ref(false);
    const isCancat = ref(false);
    onMounted(() => {
      // 模拟资源加载
      setTimeout(() => {
        isLoaded.value = true;
      }, 50);
    });
    // return { isLoaded };
    let is_device = ref('pc');
    const setCancat=()=>{
      isCancat.value = true
      console.log(isCancat.value)
    }
    const notCancat=()=>{
      isCancat.value = false
      console.log(isCancat.value)
    }
    // 判断屏幕尺寸
    if (window.matchMedia('(min-width: 1025px)').matches) {
      console.log('pc', window)
      // 导入电脑端样式
      is_device.value = 'pc'
      import('@/assets/css/pc.css');
    } else if (window.matchMedia('(min-width: 751px)').matches) {
      // 导入ipad端样式
      console.log('ipad', window)
      is_device.value = 'ipad';
      import('@/assets/css/ipad.css');
    } else {
      // 导入移动端样式
      console.log('phone', window)
      is_device.value = 'phone'
      import('@/assets/css/phone.css');
    }

    return { is_device, isLoaded,isCancat, setCancat,notCancat }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 200px;
}

.item {
  display: flex;
  padding: 20px 0;
}

.title {
  font-size:4rem;

}

.cont {
  font-size: 4rem;
  color: rbg(77, 133, 255);
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 1000;
}

::-webkit-scrollbar {
  width: 0px;
}

/* @media screen and (min-width: 1025px) {
  .box1 {
    padding: 0 29.3rem;
    display: flex;
    justify-content: space-between;
  }

  .logo-img {
    height: 11.7rem;
  }

  .box1-title {
    padding: 4.1rem 3.3rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 2.5rem;
    color: #0a9e3d;
    line-height: 3.5rem;
  }

  .box2 {
    background: #efffdb;
    padding: 2.7rem 29.3rem 0;
    position: relative;
    height: 61.3rem;
  }

  .phone-img {
    position: absolute;
    top: 2.7rem;
    right: 29rem;
    z-index: 3;
    width: 79.2rem;
    height: 62rem;
  }

  .wenan-img {
    position: absolute;
    top: 13.3rem;
    left: 38.3rem;
    width: 48rem;
    height: 31.6rem;
  }

  .zhuang-img {
    position: relative;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 14.4rem;
    margin-top: 46.9rem;
  }

  .box3 {
    padding: 6rem 34.3rem 8.7rem;
  }

  .box3-img {
    width: 100%;
    height: 100%;
  }

  .box3-1 {
    padding: 0 33.4rem;
    margin-bottom: 3.3rem;
  }

  .box3-2 {
    padding: 0 4.9rem;
    margin-bottom: 8.9rem;
  }

  .box3-3 {
    position: relative;
  }

  .box3-3-1 {
    padding: 0 33.4rem;
  }

  .chatu1 {
    position: absolute;
    width: 25.2rem;
    height: 12.5rem;
    right: 2rem;
    top: 6.3rem;
  }

  .chatu2 {
    position: absolute;
    width: 16.5rem;
    height: 6rem;
    left: 6rem;
    top: 6.8rem;
  }

  .box3-p {
    font-family: STYuanti-SC, STYuanti-SC;
    font-weight: 400;
    font-size: 3.2rem;
    color: #bbbbbb;
    line-height: 4.4rem;
    text-align: center;
    margin-bottom: 4.9rem;
  }

  .box3-div {
    display: flex;
    justify-content: space-between;
  }

  .box3-item {
    width: 27.5rem;
    height: 35.2rem;
  }

  .box4 {
    padding: 6rem 29.3rem 3.9rem;
    background: #f8f8f8;
  }

  .box4-1 {
    padding: 0 27.3rem;
    margin-bottom: 3.5rem;
  }

  .box5 {
    padding: 6rem 29.3rem 0;
  }

  .box5-1 {
    padding: 0 38.4rem;
    margin-bottom: 4.4rem;
  }

  .box5-4 {
    margin-bottom: 2rem;
  }

  .box5-5 img {
    position: relative;
    bottom: -0.5rem;
  }

  .box6 {
    padding: 4.6rem 36rem;
    background: #22be56;
  }

  .box6 p {
    font-weight: 400;
    font-size: 1.6rem;
    color: #ffffff;
    line-height: 3.6rem;
    margin: 0;
  }
}
@media screen and (min-width: 751px) {
  .box1 {
    padding: 0 11.4rem;
    display: flex;
    justify-content: space-between;
  }

  .logo-img {
    height: 20rem;
  }

  .box1-title {
    padding: 7rem 5.6rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 4.4rem;
    color: #0a9e3d;
    line-height: 6rem;
  }

  .box2 {
    background: #efffdb;
    padding: 4.6rem 11.4rem 0;
    position: relative;
    height: 104.6rem;
  }

  .phone-img {
    position: absolute;
    top: 4.6rem;
    right: 13.8rem;
    z-index: 2;
    width: 138.6rem;
    height: 105.8rem;
  }

  .wenan-img {
    position: absolute;
    top: 25.8rem;
    left: 11.6rem;
    width: 72.8rem;
    height: 48rem;
    z-index: 3;
  }

  .zhuang-img {
    position: relative;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 24.6rem;
    margin-top: 80rem;
  }

  .box3 {
    padding: 10rem 11.4rem 2.6rem;
  }

  .box3-img {
    width: 100%;
    height: 100%;
  }

  .box3-1 {
    padding: 0 42.8rem;
    margin-bottom: 5.6rem;
  }

  .box3-2 {
    margin-bottom: 15.2rem;
  }

  .box3-3 {
    position: relative;
  }

  .box3-3-1 {
    padding: 0 42.8rem;
  }

  .chatu1 {
    position: absolute;
    width: 42.4rem;
    height: 20.1rem;
    right: 0;
    top: 19.6rem;
  }

  .chatu2 {
    position: absolute;
    width: 27.6rem;
    height: 10.8rem;
    left: 8.4rem;
    top: 11.8rem;
  }

  .box3-p {
    font-family: STYuanti-SC, STYuanti-SC;
    font-weight: 400;
    font-size: 5.4rem;
    color: #bbbbbb;
    line-height: 7.6rem;
    text-align: center;
    margin-bottom: 8.4rem;
  }

  .box3-div {
    padding: 0 31.2rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .box3-item {
    width: 46.8rem;
    height: 60rem;
    margin-bottom: 8rem;
  }

  .box4 {
    padding: 10rem 11.4rem 5.6rem;
    background: #f8f8f8;
  }

  .box4-1 {
    padding: 0 24rem;
    margin-bottom: 6rem;
  }

  .box5 {
    padding: 10rem 6.8rem 0;
  }

  .box5-1 {
    padding: 0 47.4rem;
    margin-bottom: 7.6rem;
  }

  .box5-4 {
    margin-bottom: 3.4rem;
  }

  .box5-5 img {
    position: relative;
    bottom: -0.2rem;
  }

  .box6 {
    padding: 8rem 14.6rem;
    background: #22be56;
  }

  .box6 p {
    font-weight: 400;
    font-size: 2.8rem;
    color: #ffffff;
    line-height: 6.2rem;
    margin: 0;
  }
}
@media screen and (max-width: 750px) {
  .box1 {
    padding: 0 30rem;
    display: flex;
    justify-content: space-between;
  }

  .logo-img {
    height: 15rem;
  }

  .box2 {
    background: #efffdb;
    padding: 3.5rem 0 0;
    position: relative;
    height: 95rem;
  }

  .phone-img {
    position: absolute;
    top: 3.5rem;
    right: 0;
    z-index: 2;
    width: 91.75rem;
    height: 91.5rem;
  }

  .wenan-img {
    position: absolute;
    top: 6.75rem;
    left: 5.5rem;
    width: 57rem;
    height: 37.5rem;
    z-index: 3;
  }

  .zhuang-img {
    position: relative;
    z-index: 1;
    bottom: 0;
    width: 100%;
    height: 32.5rem;
    margin-top: 62.5rem;
  }

  .box3 {
    padding: 7.5rem 0 2.5rem;
  }

  .box3-img {
    width: 100%;
    height: 100%;
  }

  .box3-1 {
    padding: 0 15.25rem;
    margin-bottom: 1.75rem;
  }

  .box3-2 {
    margin-bottom: 10rem;
  }

  .box3-3 {
    position: relative;
  }

  .box3-3-img {
    padding: 0 15.5rem;
  }

  .box3-p {
    font-family: STYuanti-SC, STYuanti-SC;
    font-weight: 400;
    font-size: 4rem;
    color: #bbbbbb;
    line-height: 5.5rem;
    text-align: center;
    margin-bottom: 6rem;
    padding: 0 11rem;
  }

  .box3-div {
    padding: 0 9.25rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .box3-item {
    width: 35rem;
    height: 45rem;
    margin-bottom: 5rem;
  }

  .box4 {
    padding: 7.5rem 5.5rem;
    background: #f8f8f8;
  }

  .box4-1 {
    margin-bottom: 6rem;
  }

  .box4-2 {
    padding: 0 2.75rem;
  }

  .box5 {
    padding: 7.5rem 0.5rem 0;
  }

  .box5-1 {
    padding: 0 15.75rem;
    margin-bottom: 5.5rem;
  }

  .box5-7 {
    margin-bottom: 7rem;
  }

  .box5-8 img {
    position: relative;
    bottom: -0.5rem;
  }

  .box6 {
    padding: 8.5rem 7rem;
    background: #22be56;
  }

  .box6 p {
    font-weight: 400;
    font-size: 3rem;
    color: #ffffff;
    line-height: 6.75rem;
    margin: 0;
  }
} */
</style>
